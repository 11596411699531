@import "bootstrap/dist/css/bootstrap.min.css";
@import '../node_modules/ngx-toastr/toastr.css';

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
  }
  
  .poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  












  .text-class{
    font-size: 3.125rem;
    color: white;
  }
  .desc{
    font-size: 20px !important;
    color: white !important;
  }
 
  
  .clr{
    color: #FBE07E;
  }
  .btnjoin{
    background-color: #92D590;
    font-size: 26px;
    line-height: 26px;
    color: #1E4C53;
    padding: 10px 20px 10px 20px;
    border-radius: 30px;
  }
  .btnjoin:hover{
    background-color: #92D590;
    font-size: 26px;
    line-height: 26px;
    color: #1E4C53;
    padding: 10px 20px 10px 20px;
    border-radius: 30px;
  }
  .btnjoin1{
    background-color: #FBE07E;
    font-size: 26px;
    line-height: 26px;
    color: #1E4C53;
    padding: 10px 20px 10px 20px;
    border-radius: 30px;
  }
  .btnjoin1:hover{
    background-color: #FBE07E;
    font-size: 26px;
    line-height: 26px;
    color: #1E4C53;
    padding: 10px 20px 10px 20px;
    border-radius: 30px;
  }
  .bordrad{
    border-radius: 30px 30px 30px 30px !important;
  }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
